import './style.scss'

import $ from 'jquery';
import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'


$.fn.random = function() {
	const randomIndex = Math.floor(Math.random() * this.length);
	return $(this[randomIndex]);
};

$(document).ready(() => {
	callback()
})
/* Callbacks */

// intital callback init
function callback() {
	logoRandom()
	console.log('hi');

	video()

	marquee()
}


function logoRandom() {

	const item = $('.logos li').random()

	item.addClass('show')


}


function video() {
	const muteButton = $('.mute')

	muteButton.on('click', () => {

		if (muteButton.hasClass('sound-on')) {
			muteButton.removeClass('sound-on')
			$("video").prop('muted', true)
		} else {
			muteButton.addClass('sound-on')
			$("video").prop('muted', false)
		}
	});
}


function marquee() {
	const options = {
		type: 'loop',

		autoScroll: {
			speed: .8,
			perPage: 1,
			pauseOnHover: false,
			pauseOnFocus: false
		},

		// autoScroll: false,
		pagination: false,

		// drag: 'free',

		pauseOnHover: false,
		pauseOnFocus: false,

		clones: 2,
		perPage: 1,

		autoWidth: true,

		arrows: false,

		interval: 100,
		speed: 100
	}


	const splide = new Splide('.text.splide', options)

	splide.mount({ AutoScroll })

}
